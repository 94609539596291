import { useEffect, useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useFetchPlans } from "../../hooks/useFetchPlans";
import styles from "./styles.module.scss";
import { Button } from "@common/index";
import StripeCardElements from "../stripeCardElements";
import { useLocation, useNavigate } from "react-router";
import { successToast, errorToast } from "@utils/toaster";
import { CardLogos } from "../cardLogos/index";
import { axiosInstance } from "@api";
import { useSelector } from "react-redux";
import { updateDataLayer } from "@utils/updateDataLayer";

export const Card = ({ isInModal, id }) => {
  const navigate = useNavigate();
  const elements = useElements();
  const stripe = useStripe();
  const location = useLocation();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user.userInfo);
  const [fieldErrors, setFieldErrors] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { initiatePayment, activePlan } = useFetchPlans();

  useEffect(() => {
    if (!error) {
      return;
    }
    errorToast({ message: error });
  }, [error]);

  const handleChange = (event) => {
    const { elementType, error, empty } = event;

    setFieldErrors((prev) => ({
      ...prev,
      [elementType]: error ? true : empty,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    const cardNumber = elements.getElement("cardNumber");
    const cardExpiry = elements.getElement("cardExpiry");
    const cardCvc = elements.getElement("cardCvc");

    setFieldErrors({
      cardNumber: !cardNumber?._complete,
      cardExpiry: !cardExpiry?._complete,
      cardCvc: !cardCvc?._complete,
    });

    setIsSubmitted(true);
    setTimeout(() => setIsSubmitted(false), 200);

    if (
      !cardNumber?._complete ||
      !cardExpiry?._complete ||
      !cardCvc?._complete
    ) {
      return;
    }

    setProcessing(true);
    setError(null);

    try {
      const { stripeClientSecret, subscriptionId } = await initiatePayment({
        paymentMethod: "CREDIT_CARD",
        paymentProvider: "STRIPE",
      });

      const payload = await stripe.confirmCardPayment(stripeClientSecret, {
        payment_method: { card: elements.getElement("cardNumber") },
      });

      if (payload.error) {
        setError(`Payment failed: ${payload.error.message}`);
        return;
      } else {
        setError(null);
      }

      await axiosInstance.post("/auth/send-pin-to-email", {
        email: user?.email,
      });

      const userId = localStorage.getItem("userId");

      updateDataLayer({
        event: "Purchase",
        paymentMethod: "CREDIT_CARD",
        plan: activePlan.title,
        currency: activePlan.currency,
        price: +activePlan.priceAmount / 100,
        userId,
        subscriptionId,
      });

      if (location.pathname !== "/upsale") {
        navigate("/upsale");
      } else {
        navigate("/activate");
      }
      successToast({ message: "Payment successful! Thank you!" });
    } catch (error) {
      setError(error.response.data.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className={styles.cardWrapper}>
      {!isInModal && (
        <>
          <div className={styles.guarantee}>
            <p>30-day money-back guarantee</p>
          </div>
          <CardLogos />
        </>
      )}
      <form id="payment-form" onSubmit={handleSubmit} className={styles.form}>
        <StripeCardElements
          onChange={handleChange}
          errors={fieldErrors}
          isSubmitted={isSubmitted}
        />
        <div className={styles.control}>
          <Button
            className={`${styles.blackButton} ${id}`}
            onClick={handleSubmit}
            disabled={processing}
          >
            {processing ? (
              <div className={styles.spinner}></div>
            ) : (
              <p style={{ margin: "0" }} className={id}>
                CONTINUE
              </p>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActivePlan } from "src/redux/slices/plans";

export const useUpsalePlans = () => {
  const dispatch = useDispatch();
  const { activePlan, upsalePlans } = useSelector((state) => state.plans);
  const { userSubscription, isSubscriptionLoading } = useSelector(
    (state) => state.user
  );

  const [filteredPlans, setFilteredPlans] = useState([]);

  const isSomatic = process.env.REACT_APP_PRODUCT === "SOMATIC";
  const mainProduct = isSomatic ? "SOMATIC" : "EASYFIT";

  useEffect(() => {
    if (isSubscriptionLoading || !upsalePlans.length || !userSubscription)
      return;

    const mainSubscription = userSubscription.find((sub) =>
      sub.subscriptionPlan.products.includes(mainProduct)
    );

    const billingPeriodDuration =
      mainSubscription?.subscriptionPlan?.billingPeriodDuration || 4;

    const filtered = upsalePlans.filter(
      (plan) => plan.billingPeriodDuration === billingPeriodDuration
    );

    // Fallback to 4-week plans if no matching plans found
    const plansToUse = filtered.length
      ? filtered
      : upsalePlans.filter((plan) => plan.billingPeriodDuration === 4);

    setFilteredPlans(plansToUse);

    if (!activePlan || !plansToUse.find((plan) => plan.id === activePlan.id)) {
      const preSelectedPlan = plansToUse.find(
        (plan) => plan.uiDisplayOptions?.focused
      );
      dispatch(setActivePlan(preSelectedPlan || plansToUse[0]));
    }
  }, [
    userSubscription,
    upsalePlans,
    activePlan,
    isSubscriptionLoading,
    dispatch,
    mainProduct
  ]);

  return filteredPlans;
};

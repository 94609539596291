import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import average from "./img/average.png";
import { capitalizeFirstLetter } from "@utils/formatProfile";

export const ProfileCard = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const ms = localStorage.getItem("ms");
  const questions = userInfo?.questions;

  const weightGoal =
    ms === "us" ? questions?.qqGoalWeightLbs : questions?.qqGoalWeightKg;

  const storedGoals = JSON.parse(localStorage.getItem("hopeToAchieve")) || [];
  const targetBody = JSON.parse(localStorage.getItem("targetBody")) || "Toned";

  const defaultGoal = "Reduce stress and anxiety";
  const goalText = storedGoals.length ? formatGoals(storedGoals) : defaultGoal;

  function formatGoals(goals) {
    if (goals.length === 1) return capitalizeFirstLetter(goals[0]);
    return capitalizeFirstLetter(
      goals.slice(0, -1).join(", ") + " and " + goals[goals.length - 1]
    );
  }

  return (
    <div className={styles.profileCard}>
      <h2>This plan is based on your needs and parameters:</h2>
      <div className={styles.wrapper}>
        <div className={styles.profileChart}>
          <div className={styles.clouds}>
            <div className={styles.cloud}>
              <p>Weight goal</p>{" "}
              <h5>
                {weightGoal} {ms === "us" ? "lbs" : "kg"}
              </h5>
            </div>
            <div className={styles.cloud}>
              <p>Target body</p> <h5>{targetBody}</h5>
            </div>
            <div className={styles.cloud}>
              <p>Age</p> <h5>{questions?.qqAge}</h5>
            </div>
          </div>
          <img src={average} className={styles.bodyImg} />
          <div className={styles.goal}>
            <div className={styles.goalCloud}>{goalText}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { ChartGainWeight, ChartLoseWeight } from "../../../prediction/charts";
import { getPredictionData } from "../../../prediction/utils/getPredictionData";

export const PaywallChart = () => {
  const user = useSelector((state) => state.user.userInfo);
  const ms = localStorage.getItem("ms");

  const { goal, predictedDate, eventDate, isLoseWeight, axisY } =
    getPredictionData(user);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.text}>
          <p className={styles.subtitle}>
            According to the information you have provided us, you'll achieve
            your goal weight of
          </p>
          <p className={styles.predictedData}>
            {goal} {ms === "us" ? "lbs" : "kg"} <span>by</span>{" "}
            {new Date(eventDate || predictedDate).toLocaleString("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })}
          </p>
        </div>

        <div className={styles.chart}>
          <div className={styles.axisx}>
            <span>
              {" "}
              {new Date().toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
              })}
            </span>
            <span>
              {" "}
              {new Date(eventDate || predictedDate).toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
              })}
            </span>
          </div>
          <div className={styles.axisy}>
            {axisY.map((value, index) => {
              return <span key={index}>{value}</span>;
            })}
          </div>
          <div
            className={
              isLoseWeight
                ? `${styles.label}`
                : `${styles.label} ${styles.gain}`
            }
          >
            Goal <br />
            {goal} {ms === "us" ? "lbs" : "kg"}
          </div>

          {isLoseWeight ? <ChartLoseWeight /> : <ChartGainWeight />}
        </div>
      </div>
    </div>
  );
};

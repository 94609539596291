import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { getUserInfoThunk } from "../../../../redux/slices/userSlice";
import {
  FAQSection,
  Guarantee,
  Partners,
  PaywallChart,
  PersonalPlan,
  Plan,
  ResultsSlider,
  Reviews,
  Timer,
  Transform
} from "src/routes/onboarding/shared/components/paywall/components";
import { DiscountProvider } from "src/routes/onboarding/shared/components/paywall/DiscountContext";
import Normal from "../assets/img/normal.png";
import Overweight from "../assets/img/overweight.png";
import Obese from "../assets/img/obese.png";
import Underweight from "../assets/img/underweight.png";
import Lean from "../assets/img/lean.png";
import Toned from "../assets/img/toned.png";
import Average from "../assets/img/average.png";
import SlightlyLighter from "../assets/img/few_pounds_less.png";
import { ReliefSlider } from "./components/lastingRelief";
import { UpsaleModal } from "../../shared/components/paywall/components/upsaleModal";
import { AdditionalDiscount } from "src/routes/onboarding/shared/components/paywall/components/additionalDiscount";
import { Benefits } from "./components/benefits";
import { ProfileCard } from "./components/profileCard";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "src/firebase.config";

const BMI_TYPE_IMAGES = (desiredBodyType) => {
  const After = {
    Lean,
    Toned,
    Average,
    SlightlyLighter
  };
  return {
    Normal,
    Overweight,
    Obese,
    Underweight,
    After: After[desiredBodyType.replace(/\\?"/g, "")]
  };
};

export const SomaticPaywall = () => {
  const fetchPaywallVariant = async () => {
    try {
      await fetchAndActivate(remoteConfig);
      const paywallVariant = getValue(remoteConfig, "paywall").asString();
      return paywallVariant;
    } catch (err) {
      console.error("Remote Config fetch failed:", err);
      return "default";
    }
  };
  const [variant, setVariant] = useState(null);
  const dispatch = useDispatch();
  const [upsaleModalVisible, setUpsaleModalVisible] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const profileCardRef = useRef(null);
  const planRef = useRef(null);

  useEffect(() => {
    const loadVariant = async () => {
      const paywallVariant = await fetchPaywallVariant();
      setVariant(paywallVariant);
    };
    loadVariant();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (profileCardRef.current && !showTimer) {
        const rect = profileCardRef.current.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

        if (isVisible) {
          setShowTimer(true);
          window.removeEventListener("scroll", handleScroll);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollToPlan = () => {
    planRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const faqSectionRef = useRef(null);

  const name = localStorage.getItem("name");

  const additionalDiscount = localStorage.getItem("additionalDiscount");

  const handleCheckoutClose = () => {
    setUpsaleModalVisible(true);
    localStorage.setItem("additionalDiscount", true);
  };

  return (
    <DiscountProvider>
      <div className={styles.layout}>
        {showTimer && (
          <Timer
            onGetMyPlanClick={handleScrollToPlan}
            onTimeEnd={() => setShowTimer(false)}
          />
        )}
        {additionalDiscount && <AdditionalDiscount />}
        <Transform
          images={BMI_TYPE_IMAGES(
            localStorage.getItem("targetBody") || "Toned"
          )}
          title="Somatic exercise"
          header="After the program"
        />
        <PersonalPlan isSomatic />
        <div ref={planRef}>
          <Plan
            id={1}
            title="AI-optimized plan"
            isMetrics
            onCloseCheckout={handleCheckoutClose}
            additionalDiscount={additionalDiscount}
          />
        </div>
        <div ref={profileCardRef}>
          <ProfileCard />
        </div>
        <PaywallChart />
        <Plan
          id={2}
          title={"get visible results in 4 weeks!"}
          name={name}
          onCloseCheckout={handleCheckoutClose}
          additionalDiscount={additionalDiscount}
        />
        <Benefits />
        <Partners />
        <ReliefSlider />
        <ResultsSlider />
        <div ref={faqSectionRef}>
          <FAQSection />
        </div>
        <Reviews />
        <Plan
          id={3}
          title="Get visible results in 4 weeks with AI‑optimized plan!"
          isMetrics
          onCloseCheckout={handleCheckoutClose}
          additionalDiscount={additionalDiscount}
        />
        <Guarantee />
        <footer className={styles.footer}>
          Straiton Limited | Alpha Tower, Floor 1, Office 11, <br /> Pavlou
          Nirvana- Aipeias, Limassol, 3021, Cyprus
        </footer>
      </div>
      {upsaleModalVisible && (
        <UpsaleModal
          onClose={() => setUpsaleModalVisible(false)}
          text="Somatic Exercise Plan."
        />
      )}
    </DiscountProvider>
  );
};

import { Button } from "@common/index";
import styles from "./styles.module.scss";
import { SOMATIC_KEYS, useName } from "src/routes/onboarding/shared";
import { useNavigate } from "react-router";
import { trackUserEvent } from "@utils/updateDataLayer";

const title = "What's your name?";
export const Name = () => {
  const navigate = useNavigate();
  const onNextStep = () => {
    navigate("/prediction");
  };
  const { name, isValid, handleChange, onSubmit } = useName(onNextStep);

  const handleSubmit = async () => {
    localStorage.setItem(SOMATIC_KEYS.name, JSON.stringify(name));
    onSubmit();
    trackUserEvent("", {
      screenId: "Name",
      screenTitle: title
    });
  };

  return (
    <div className={styles.container} id="breakdown">
      <div className={styles.content}>
        <h2>{title}</h2>
        <div className={styles.input}>
          <input
            placeholder="Name"
            type="text"
            value={name}
            onChange={(e) => handleChange(e.currentTarget.value)}
          />
        </div>
      </div>
      <div className={styles.action}>
        <Button onClick={handleSubmit} disabled={!isValid}>
          Continue
        </Button>
      </div>
    </div>
  );
};

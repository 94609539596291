import { Link, useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
import { DATA } from "./data";
import { useEffect, useState } from "react";
import nextImage from "../peopleChosen/img/bg.jpg";
import authService from "@api/services/authService";
import { updateDataLayer } from "@utils/updateDataLayer";
import { errorToast } from "@utils/toaster";
import { Loader } from "@common/loader";

export const StartJourney = ({ onNextStep }) => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const startAuth = async () => {
      try {
        setLoaded(false);
        await authService.anonymousSignUp();
        updateDataLayer({
          event: "FirstQuestionShown",
          userId: localStorage.getItem("userId")
        });
      } catch (error) {
        errorToast({ message: "An error occurred. Please try again." });
        navigate("/");
      } finally {
        setLoaded(true);
      }
    };

    if (!localStorage.getItem("token")) {
      startAuth();
      return;
    }
    setLoaded(true);

    const img = new Image();
    img.src = nextImage;
  }, [navigate]);

  const handleNextStep = (value) => {
    onNextStep(value);
  };

  return loaded ? (
    <div className={styles.container} id="breakdown">
      <h1>Start your fitness journey</h1>
      <p className={styles.choose}>Choose your age bracket</p>
      <p className={styles.quiz}>1-min quiz</p>
      <div className={styles.cards}>
        {DATA.map((item) => (
          <div
            key={item.value}
            onClick={() => handleNextStep(item.value)}
            className={styles.card}
          >
            <img src={item.image} alt={item.value} />
            <div className={styles.label}>Age: {item.value}</div>
          </div>
        ))}
      </div>
      <p className={styles.privacy}>
        By choosing your age and continuing you agree to our{" "}
        <Link to="/terms-of-use">Terms of Service</Link> |
        <Link to="/privacy-policy"> Privacy Policy</Link>.<br />
        Please review before continuing.
      </p>
    </div>
  ) : (
    <Loader />
  );
};
